<template>
  <div class="createEnterprise">
    <div class="panel demoWrapper">
      <div class="basic-info">
        <div class="basic-info-top">Basic info</div>
        <div>
          <el-form
            ref="formLabelAlign"
            label-width="180px"
            :model="formLabelAlign"
            :rules="rules1"
            :inline="true"
          >
            <el-form-item label="Enterprise Sign:" required="required">
              <el-radio v-model="radio1" label="1">
                General Enterprise
              </el-radio>
            </el-form-item>

            <!--  -->
            <el-form-item
              label="Enterprise Name:"
              prop="enterpriseName"
              style="margin-left: 60px"
            >
              <el-input
                v-model="formLabelAlign.enterpriseName"
                placeholder="Please Enter"
                maxlength="120"
              />
            </el-form-item>

            <el-form-item label="Enterprise Type:" prop="enterpriseType">
              <el-select
                v-model="formLabelAlign.enterpriseType"
                placeholder="Others"
              >
                <el-option
                  v-for="item in optionsEnter"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="Enterprise Email:" prop="enterpriseEmail">
              <el-input
                v-model="formLabelAlign.enterpriseEmail"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Enterprise Phone:" prop="enterprisePhone">
              <el-input
                v-model="formLabelAlign.enterprisePhone"
                oninput="value=value.replace(/[^\d]/g,'')"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item
              label="License Type:"
              prop="licenseType"
              required="required"
            >
              <el-select
                v-model="formLabelAlign.licenseType"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionsLicense"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="License Number:" prop="licenseNumber">
              <el-input
                v-model="formLabelAlign.licenseNumber"
                type="text"
                placeholder="Please Enter"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Organization Code:" prop="organizationCode">
              <el-input
                v-model="formLabelAlign.organizationCode"
                type="text"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Legal Person Name:" prop="corporationName">
              <el-input
                v-model="formLabelAlign.corporationName"
                type="text"
                placeholder="Please Enter"
                onkeyup="value=value.replace(/[^a-zA-Z]/g,'')"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Legal Person Mobile:" prop="corporationPhone">
              <el-input
                v-model="formLabelAlign.corporationPhone"
                onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                type="text"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Credentials Type:" prop="credentialsType">
              <el-select
                v-model="formLabelAlign.credentialsType"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionscrdent"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="Identification Number:"
              prop="credentialsNumber"
              class="formLine"
            >
              <el-input
                v-model="formLabelAlign.credentialsNumber"
                type="text"
                placeholder="Please Enter"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="100"
              />
            </el-form-item>
            <!-- 证件号码 -->
            <!-- <el-form-item
              label="Credentials Number:"
              prop="credentialsNumber"
              class="formLine"
            >
              <el-input
                v-model="formLabelAlign.credentialsNumber"
                type="text"
                placeholder="Please Enter"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="100"
              />
            </el-form-item> -->

            <el-form-item label="Industry Type:" prop="industry">
              <el-select
                v-model="formLabelAlign.industry"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionsindustry"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="Employee:" prop="employeeSize">
              <el-select
                v-model="formLabelAlign.employeeSize"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionsEmploy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="Enterprise Address:"
              prop="address"
              class="formLine-last"
            >
              <el-cascader
                :props="optionsaddress"
                placeholder="Please Choose"
                class="cascader"
                clearable
                @change="changeOptionsaddress"
              />
              <el-input
                v-model="formLabelAlign.address"
                type="text"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="panel demoWrapper">
      <div class="basic-info">
        <div class="basic-info-top">Invoice info</div>
        <div class="">
          <el-form
            ref="formLabelAlign1"
            label-width="235px"
            :model="formLabelAlign1"
            :rules="ifTH ? rulesTH : rules2"
            :inline="true"
          >
            <div v-if="!ifTH">
              <el-form-item
                label="Invoice Register Enterprise Name:"
                prop="invoiceCompanyName"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceCompanyName"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Taxpayer Identification Number:"
                prop="invoiceTaxpayerNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceTaxpayerNumber"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Name of Deposit Bank:"
                prop="invoiceBankDeposit"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceBankDeposit"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Bank Account No:"
                prop="invoiceBankNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceBankNumber"
                  type="text"
                  placeholder="Please Enter"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Address:"
                prop="invoiceRegisterAddress"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterAddress"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Phone No:"
                prop="invoiceRegisterPhone"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterPhone"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Zip Code:"
                prop="invoiceRegisterZipcode"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterZipcode"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item
                label="Name:"
                prop="invoiceCompanyName"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceCompanyName"
                  type="text"
                  placeholder="Please Enter "
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Branch Name:"
                prop="invoiceBranchName"
                class="formLine"
              >
                <el-select
                  v-model="formLabelAlign1.invoiceBranchName"
                  placeholder="Please Choose"
                >
                  <el-option
                    v-for="item in BranchNameList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <div class="left_box">
                <b>*</b>
                <el-form-item
                  label="Branch ID:"
                  prop="invoiceBranchId"
                  class="formLine"
                >
                  <el-input
                    v-model="formLabelAlign1.invoiceBranchId"
                    type="text"
                    placeholder="Please Enter"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="100"
                    :disabled="
                      formLabelAlign1.invoiceBranchId == '00000' ||
                        formLabelAlign1.invoiceBranchName == 3
                    "
                  />
                </el-form-item>
              </div>

              <el-form-item
                label="Tax ID:"
                prop="invoiceTaxpayerNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceTaxpayerNumber"
                  type="text"
                  placeholder="Please Enter"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Address:"
                prop="invoiceRegisterAddress"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterAddress"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Postal Code:"
                prop="invoiceRegisterZipcode"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterZipcode"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Email:"
                prop="invoiceRegisterEmail"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterEmail"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="panel demoWrapper">
      <div class="basic-info basic-info1">
        <div class="basic-info-top">Bank Account info</div>
        <div>
          <el-form
            ref="formLabelAlign2"
            label-width="260px"
            :model="formLabelAlign2"
            :rules="rules3"
            :inline="true"
          >
            <el-form-item
              label="Enterprise Bank of Deposit Branch:"
              prop="bankDeposit"
              class="formLine"
            >
              <el-input
                v-model="formLabelAlign2.bankDeposit"
                type="text"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item
              label="Enterprise Bank Account No:"
              prop="bankNumber"
              class="formLine"
            >
              <el-input
                v-model="formLabelAlign2.bankNumber"
                type="text"
                placeholder="Please Enter"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="100"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="panel button-add-r">
      <!-- <div class="button-add-r"> -->
      <!-- <el-button class="submitted" @click="apply()">Submitted Materials</el-button> -->
      <el-button
        type="primary"
        style="font-size: 14px;"
        @click="apply()"
      >
        Apply
      </el-button>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css';
import { addBusiness, getAddress } from '@/api/enterprise/enterprise';

export default {
  data() {
    let _this = this;
    return {
      // 添加企业信息数据
      radio: '',
      radio1: '1',
      // input123: '',
      // 层级选择器
      value: '',
      options: [],
      //人员规模
      optionsEmploy: [
        {
          value: '1-49',
          label: '1-49'
        },
        {
          value: '50-99',
          label: '50-99'
        },
        {
          value: '100-499',
          label: '100-499'
        },
        {
          value: '500-999',
          label: '500-999'
        },
        {
          value: '>1000',
          label: '>1000'
        }
      ],
      // 企业类型
      optionsEnter: [
        {
          value: 1,
          label: 'Public/Listed Company'
        },
        {
          value: 2,
          label: 'Private Company'
        },
        {
          value: 3,
          label: 'State-owned Company'
        },
        {
          value: 4,
          label: 'Cooperation'
        },
        {
          value: 5,
          label: 'Foundation'
        },
        {
          value: 6,
          label: 'Institution'
        },
        {
          value: 7,
          label: 'Government'
        },
        {
          value: 8,
          label: 'Others'
        }
      ],
      BranchNameList: [
        {
          value: 1,
          label: 'Head Office'
        },
        {
          value: 2,
          label: 'Branch'
        },
        {
          value: 3,
          label: 'Individual'
        }
      ],
      // 行业类型
      optionsindustry: [
        {
          value: 1,
          label: 'Banking'
        },
        {
          value: 2,
          label: 'Media & Telecommunication'
        },
        {
          value: 3,
          label: 'Transportation & Logistics'
        },
        {
          value: 4,
          label: 'Government & Public Sector'
        },
        {
          value: 5,
          label: 'Manufacturing'
        },
        {
          value: 6,
          label: 'Retail'
        },
        {
          value: 7,
          label: 'Hospitality'
        },
        {
          value: 8,
          label: 'Leisure and Entertainment '
        },
        {
          value: 9,
          label: 'Healthcare'
        },
        {
          value: 10,
          label: 'Education'
        },
        {
          value: 11,
          label: 'Finance & Real Estate'
        },
        {
          value: 12,
          label: 'Enery &Materials'
        },
        {
          value: 13,
          label: 'Primary Industry'
        },
        {
          value: 99,
          label: 'Others'
        }
      ],
      // 证照类型
      optionsLicense: [
        {
          value: 0,
          label: 'Business License'
        }
      ],
      // 法人证件类型
      optionscrdent: [
        {
          value: 0,
          label: 'ID Card'
        },
        {
          value: 1,
          label: 'Passport'
        },
        {
          value: 2,
          label: 'Other'
        }
      ],
      id: process.env.VUE_APP_ADDRESS,
      //  企业地址模拟数据
      optionsaddress: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let par = {};
          if (level === 0) {
            par.id = _this.id;
          } else {
            par.id = value;
          }
          getAddress(par).then(res => {
            let leave = null;
            if (_this.id == 10000000) {
              leave = 3;
            } else if (_this.id == 764) {
              leave = 2;
            }
            const nodes = res.data.map(item => {
              return {
                value: item.id,
                label: item.name,
                leaf: level >= leave
              };
            });
            resolve(nodes);
          });
        }
      },
      labelPosition: 'left',
      // 基本信息数据
      formLabelAlign: {
        enterpriseName: '', // 企业名称
        enterpriseType: '', // 企业类型
        enterpriseEmail: '', // 企业邮箱
        enterprisePhone: '', // 企业电话
        licenseType: 0, // 证照类型
        licenseNumber: '', // 证照号码
        organizationCode: '', // 组织机构代码
        corporationName: '', // 法人名称
        corporationPhone: '', // 法人电话
        credentialsType: 0, // 法人证件类型
        credentialsNumber: '', // 证照号码
        industry: 1, // 行业类型
        employeeSize: '', // 企业人员规模
        province: 1, // 公司地址-一级地址ID
        city: 2, //'公司地址-二级地址ID',
        district: 3, // 公司地址-三级地址ID',
        town: 4, //'公司地址-四级地址ID',
        address: '', // 公司地址
        baseInfoAuditState: 0,
        invoiceInfoAuditState: 0,
        bankInfoAuditState: 0,
        // contractNo:'1',
        state: 1,
        // dataVersion: 1, //'数据版本号，乐观锁
        creator: '',
        modifier: '' //更新人:每次update时必须赋值
        // created:'1',//创建时间:sql维护,insert时赋值now()
        // modified:'1'//更新时间:sql维护,每次update时赋值为now()modifyBank: this.obj.modifyBank,
        // modifyInvoice: '',
        // modifyEnterprise: '',
        // modifyBank: '',
      },
      // 发票信息数据
      formLabelAlign1: {
        // 增票-公司注册名
        invoiceCompanyName: '',
        // 增票-纳税人识别号
        invoiceTaxpayerNumber: '',
        // 增票-开户银行
        invoiceBankDeposit: '',
        // 增票-银行账号
        invoiceBankNumber: '',
        // 增票-公司注册地址
        invoiceRegisterAddress: '',
        // 增票-公司注册电话
        invoiceRegisterPhone: '',
        // 增票-公司注册邮编
        invoiceRegisterZipcode: '',
        invoiceBranchId: '',
        invoiceRegisterEmail: '',
        invoiceBranchName: ''
      },
      // 银行信息数据
      formLabelAlign2: {
        bankDeposit: '',
        bankNumber: ''
      },

      rules1: {
        enterpriseName: [
          {
            required: true,
            message: 'Enterprise Name is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        enterpriseType: [
          {
            required: true,
            message: 'Enterprise Type is empty',
            trigger: 'change'
          }
        ],
        enterpriseEmail: [
          {
            required: true,
            message: 'Enterprise Email is empty',
            trigger: 'blur'
          },
          // { max: 100, trigger: 'blur' }
          {
            validator: function(rule, value, callback) {
              if (
                
                /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/i.test(value) ==
                false
              ) {
                callback(new Error('EnterpriseEmail format error'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        enterprisePhone: [
          {
            required: true,
            message: 'Enterprise Phone is empty',
            trigger: 'blur'
          },
          {
            max: 100,
            trigger: 'blur',
            validator: function(rule, value, callback) {
              if (/^[0-9]*$/.test(value) == false) {
                callback(new Error('Mailbox format error'));
              } else {
                callback();
              }
            }
          }
        ],
        licensetype: [
          { required: true, message: 'Licensetype is empty', trigger: 'change' }
        ],
        licenseNumber: [
          {
            required: true,
            message: 'License Number is empty',
            trigger: 'blur',
            max: 100
          }
        ],
        organizationCode: [
          {
            required: true,
            message: 'Organization Code is empty ',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        corporationName: [
          {
            required: true,
            message: 'Legal Person Name is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        corporationPhone: [
          {
            required: true,
            message: 'Legal Person Mobile is empty',
            trigger: 'blur'
          },
          { max: 50, trigger: 'blur' }
        ],
        credentialsType: [
          {
            required: true,
            message: 'Credentials Type is empty',
            trigger: 'blur'
          }
        ],
        credentialsNumber: [
          {
            required: true,
            message: 'Identification Number is empty',
            trigger: 'blur'
          },
          {
            max: 100,
            trigger: 'blur',
            validator: function(rule, value, callback) {
              if (/^[0-9]*$/.test(value) == false) {
                callback(new Error('Mailbox format error'));
              } else {
                callback();
              }
            }
          }
        ],
        industry: [
          { required: true, message: 'Industry Type is empty', trigger: 'blur' }
        ],
        employeeSize: [
          { required: true, message: 'Employee is empty', trigger: 'change' }
        ],
        address: [
          {
            required: true,
            message: 'Enterprise Address is empty',
            trigger: 'change'
          }
        ]
      },
      rulesTH: {
        invoiceCompanyName: [
          {
            required: true,
            message: 'Invoice Register Enterprise Name is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceBranchName: [
          {
            required: true,
            message: 'Please Choose Invoice Branch Name',
            trigger: 'change'
          }
        ],
        invoiceTaxpayerNumber: [
          {
            required: true,
            message: 'Place Enter Taxpayer Number',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],

        invoiceRegisterAddress: [
          {
            required: true,
            message: 'Invoice Register Address is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceRegisterZipcode: [
          {
            required: true,
            message: 'Invoice Register Zip Code is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterEmail: [
          {
            required: true,
            message: 'Invoice Register Email is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      rules2: {
        invoiceCompanyName: [
          {
            required: true,
            message: 'Invoice Register Enterprise Name is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceTaxpayerNumber: [
          {
            required: true,
            message: 'Taxpayer invoiceTaxpayerNumber is empty',
            trigger: 'blur',
            max: 100
          }
        ],
        invoiceBankDeposit: [
          {
            required: true,
            message: 'Name of Deposit Bank is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceBankNumber: [
          {
            required: true,
            message: 'Invoice Bank Account No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],

        invoiceRegisterAddress: [
          {
            required: true,
            message: 'Invoice Register Address is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceRegisterPhone: [
          {
            required: true,
            message: 'Invoice Register Phone No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterZipcode: [
          {
            required: true,
            message: 'Invoice Register Zip Code is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      rules3: {
        bankDeposit: [
          {
            required: true,
            message: 'Enterprise Bank of Deposit Branch is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        bankNumber: [
          {
            required: true,
            message: 'Enterprise Bank Account No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      ifTH: false
    };
  },
  watch: {
    'formLabelAlign1.invoiceBranchName': function(val) {
      if (this.formLabelAlign1.invoiceBranchName == 1) {
        this.formLabelAlign1.invoiceBranchId = '00000';
      } else {
        this.formLabelAlign1.invoiceBranchId = '';
      }
    }
  },
  created() {
    this.info();
    if (process.env.VUE_APP_BASE_API == '/apiid') {
      this.ifTH = false;
    } else if (process.env.VUE_APP_BASE_API == '/apith') {
      this.ifTH = true;
    }
  },
  methods: {
    changeOptionsaddress(val) {
      debugger
      //console.log(a);
      //  province: 1, // 公司地址-一级地址ID
      //   city: 2, //'公司地址-二级地址ID',
      //   district: 3, // 公司地址-三级地址ID',
      //   town: 4, //'公司地址-四级地址ID',
      if (val.length == 0) {
        this.formLabelAlign.province = 0;
        this.formLabelAlign.city = 0;
        this.formLabelAlign.district = 0;
        this.formLabelAlign.town = 0;
      } else {
        if (val && val.length === 4) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
          this.formLabelAlign.district = val[2];
          this.formLabelAlign.town = val[3];
        } else if (val && val.length === 3) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
          this.formLabelAlign.district = val[2];
        } else if (val && val.length === 2) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
        } else if (val && val.length === 1) {
          this.formLabelAlign.province = val[0];
        }
      }
    },
    // 获取层联地址
    info() {
      getAddress().then(res => {
        this.props = res.date;
      });
    },

    //  应用提交材料
    apply() {
      const p1 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign'].validate(valid => {
          if (valid) resolve();
        });
      });

      const p2 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign1'].validate(valid => {
          if (valid) resolve();
        });
      });

      const p3 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign2'].validate(valid => {
          if (valid) resolve();
        });
      });

      Promise.all([p1, p2, p3]).then(() => {
        const params = {
          ...this.formLabelAlign,
          ...this.formLabelAlign1,
          ...this.formLabelAlign2
        };
        addBusiness(params).then(res => {
          if (res.code === 200) {
            this.$message({ message: 'Operation succeeded', type: 'success' });
            this.$router.push('/businessmanagement/Business');
            this.formLabelAlign = {};
            this.formLabelAlign1 = {};
            this.formLabelAlign2 = {};
          }else{
            this.$message({
              type: 'error',
              message:res.msg
            })
          }
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.createEnterprise {
  .demoWrapper {
    /deep/.el-input__inner {
      width: 210px;
      height: 32px;
      line-height: 32px;
    }
    .basic-info-top {
      height: 67px;
      line-height: 67px;
      font-size: 20px;
      font-family: Arial;
      font-weight: normal;
      color: rgba(36, 37, 38, 1);
    }
    .basic-info {
      padding:10px 16px;
      border-radius: 8px;
      .basic-info-con {
        overflow: hidden;
        overflow-x: visible;
        padding-left: 41px;
        padding-right: 27px;
        .el-row {
          width: 100%;
        }
      }
      .basic-foot {
        height: 80px;
        // display: flex;
        padding-top: 39px;
      }
    }
    .basic-info1 {
      padding-bottom: 50px;
    }
    .basic-info2 {
      height: 300px;
      margin-bottom: 100px;
    }
    .invoice-info {
      margin-top: 20px;
    }
    // /deep/.formLine {
    //   .el-form-item__label {
    //     line-height: 20px !important;
    //     text-align: right !important;
    //   }
    // }
    /deep/ .formLine-last {
      display: flex;
      align-items: center;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .el-cascader {
          margin-right: 20px;
        }
      }
    }
    .el-form-item {
      margin-bottom: 30px;
    }
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
</style>
<style lang="less">
.createEnterprise {
  .button-add-r {
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: fixed;
    bottom: 0;
    box-shadow: 3px 5px 9px #000;
    .el-button {
      position: relative;
      left: 68%;
      width: 100px;
      // transform: translateX(-100%);
    }
  }
}
.left_box {
  width: 40%;
  display: inline;
  position: relative;
  b {
    position: absolute;
    top: 2px;
    left: 80px;
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
